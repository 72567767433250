import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Примеры работ',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Web interface
                  </div>
                <h3 className="mt-0 mb-12">
                  Проект Honda Orthia
                  </h3> 
                <p className="m-0">
                  Это мой личный проект - он был реализован и находится на <a href="https://honda-orthia.ru/">honda-orthia.ru</a>, теперь его главная страница выглядит немного иначе, но мне все еще нравится оригинальная версия, и в будущем я приведу ее в нужную форму.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/orthia.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Animation project
                  </div>
                <h3 className="mt-0 mb-12">
                  Туристический проект
                  </h3>
                <p className="m-0">
                  Этот проект был создан в процессе написания <a href="https://skillbox.ru/media/design/after_effects_sozdayem_vau_effekt_dlya_glavnoy_stranitsy/" rel="nofollow noopener" target="_blank">руководства по анимации интерфейса</a> для учебной платформы Skillbox, в будущем этот макет может быть реализован для создания отличного веб-сайта.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/france.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Animation project
                  </div>
                <h3 className="mt-0 mb-12">
                  Проект фильмография
                  </h3>
                <p className="m-0">
                  Это очень интересный проект. Он также был создан для демонстрации взаимодействия элементов интерфейса для платформы Skillbox. Выполнив <a href="https://skillbox.ru/media/design/animatsiya_interfeysa_v_after_effects/" rel="nofollow noopener" target="_blank"> все шаги в руководстве</a>, вы можете создать отличную анимацию в After Effects.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/films-kill-giants.png')}
                  alt="Features split 03"
                  width={528} 
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;